import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="iOS App Template | iOS App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/ios-mobile-app/"
    metaDescription="Featuring a clean look, Uizard's iOS template will help you create something fresh, without losing any of the familiarity users have with Apple."
    description="
    h2:Design your own iOS mobile app with our iPhone app template
    <br/>
    Looking to design your own iOS mobile app for iOS 15 onwards? Uizard's iOS App design template is here to help you do just that. To get started, simply sign up to Uizard for free and start creating your own iOS mobile app design, you could have a functional <a:https://uizard.io/prototyping/>UX prototype</a> built in minutes!
    <br/>
    h3:iOS mobile app design made easy
    <br/>
    Featuring a clean look that combines the most classic elements of iOS, Uizard's iOS app template will help you create something fresh and modern, without losing any of the familiarity users have come to expect from Apple. Designing with Uizard is super easy. As with all our <a:https://uizard.io/templates/mobile-app-templates/>mobile app templates</a>, our iOS template comes with all the must-have UI considerations baked right in.
    <br/>
    h3:An iOS 15 template that opens the door for innovation 
    <br/>
    Uizard templates are designed to encourage the creation of applications, using a familiar yet exciting interface. Uizard's iOS 15 template and theme fosters innovation and invites you to design your next iOS app using Apple's latest clean and modern look, using the easiest design tool for mobile app creation!
    "
    pages={[
      "Focus mode screen",
      "A sharing screen in Health, as in Shareplay",
      "Apple Music screen",
      "the iPhone Spotlight search screen",
      "Siri knowledge screen",
      "Photo memories screen",
    ]}
    projectCode="QrabjBR8pVhvxxwB99Yn"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of iOS 15 Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of iOS 15 Mobile App: Apple Music app"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of iOS 15 Mobile App: iPhone Sharing"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of iOS 15 Mobile App: search page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of iOS 15 Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/ios-mobile-app/ios15-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/ios-mobile-app/ios15-music.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/ios-mobile-app/ios15-sharing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/ios-mobile-app/ios15-search.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/ios-mobile-app/ios15-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
